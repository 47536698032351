import React, {useCallback, useEffect, useState} from "react";


function HelpPage() {
    const [top, setTop] = useState(false)
    const targetElement = useCallback(node => {
        if (node) {
            const boundingRect = node.getBoundingClientRect()
            if (boundingRect) {
                setTop(boundingRect.top)
            }
        }
    }, []);

    return (
        <div className="help-page">
            <h1 className="help-page-header">Hilfe</h1>
            {top && <div className="transcriptGradientTop" style={{top: top}}></div>}
            <div className="faq-list-wrapper" ref={targetElement}>
                <ul className="faq-list">
                    <li className="faq-list-item">
                        <h3>Was sind die Unterschiede zwischen "Chat V1" und "Chat V2"?</h3>
                        <p>Die Hauptunterschiede zwischen "Chat V1" und "Chat V2" liegen in der Geschwindigkeit und den zusätzlichen Funktionen von Chat V2. Während Chat V1 in der Regel ohne Probleme mit Firewalls funktioniert, zeichnet sich Chat V2 durch seine schnellere Leistung aus. Zudem enthalten die Antworten in Chat V2 den Trustscore, der die Verlässlichkeit der Antwort transparenter macht. Allerdings können bei Chat V2 in manchen Fällen Probleme mit Firewalls auftreten. Der Chat sollte dann automatisch auf V1 umstellen. Sollte das passieren, kontaktiert uns gerne – wir versuchen, die notwendigen Einstellungen vorzunehmen, damit ihr auch Chat V2 problemlos nutzen könnt.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Was ist der TrustScore?</h3>
                        <p>Der Trustscore ist ein Feature des Betriebsratsbots, das euch dabei hilft, die Verlässlichkeit der Antworten besser einzuschätzen. Da Systeme, die auf LLMs (Large Language Models) basieren, Antworten generieren können, die zwar überzeugend klingen, jedoch nicht immer exakt aus den zugrunde liegenden Quellen abgeleitet sind, birgt dies die Gefahr von Interpretationsfehlern. Der Trustscore zeigt euch auf einen Blick, wie gut die hinterlegten Quellen zu Eurer gestellten Frage passen. Das bedeutet, ihr könnt leichter erkennen, wie fundiert eine Antwort ist, ob es Potenzial für Missverständnisse gibt, und bei Bedarf die Quellen genauer überprüfen. Dieses Feature gibt Euch mehr Sicherheit, bessere Kontrolle und schafft Vertrauen im Alltag – sei es bei Entscheidungen, Recherchen oder schnellem Nachschlagen.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Wie bekomme ich die besten Antworten auf meine Fragen?</h3>
                        <p>Die besten Antworten erhältst du, wenn du dem Betriebsratsbot eine Frage stellst und nicht nur Stichwörter nutzt. Viele gängige Abkürzungen kennt der Betriebsratsbot. Solltest du jedoch keine zufriedenstellende Antwort erhalten, versuche gerne Abkürzungen zu ändern oder auszuformulieren.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Was mache ich, wenn etwas nicht funktioniert?</h3>
                        <p>Wenn du Probleme bei der Nutzung des Betriebsratsbot hast, melde dich gerne bei uns. Egal, ob per Telefon oder E-Mail, gemeinsam finden wir die Ursache für das Problem.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Was mache ich, wenn ich schon seit mehreren Minuten keine Antwort erhalten habe?</h3>
                        <p>In diesem Fall kann es gut sein, dass sich der Bot aufgehängt hat.  Lade am besten die Seite neu und stelle deine Frage erneut.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Wie kann ich alte Chatverläufe einsehen?</h3>
                        <p>Alte Chatverläufe kannst du auf der Chatseite oben rechts im Chat über das Dropdown Menü auswählen.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Warum gibt es die Möglichkeit einen neuen Chat zu beginnen?</h3>
                        <p>Du kannst einen neuen Chat beginnen, um unterschiedliche Themen in getrennten Chats zu speichern. So kannst du diese über den Chatverlauf rückblickend leichter wieder finden.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Wie kann ich Muster-Dokumente erstellen?</h3>
                        <p>Um Muster-Dokumente zu erstellen, wählst du im Menü auf der linken Seite das zweite Item von oben aus. Eine kurze Information was für ein Dokument du erstellen möchtest und was die zentralen Inhalte sein sollen, reicht aus, um den Bot ein Dokument erstellen zu lassen. In einem zweiten Schritt hast du die Möglichkeit das Dokument anzupassen. Gebe dafür einfach deine Änderungswünsche in das entsprechende Feld ein. Bist du mit dem Dokument zufrieden und möchtest ein neues Dokument erstellen wählst du „Neues Dokument erstellen“ aus. Dieser Schritt ist wichtig, da der Bot nur dann ein neues Dokument anlegt. Auf der rechten Seite kannst du alte Dokumente erneut auswählen und anzeigen lassen.</p>
                    </li>
                    <li className="faq-list-item">
                        <h3>Wie kann ich dabei helfen den Betriebsratsbot zu verbessern?</h3>
                        <p>Wir arbeiten ständig an der Verbesserung der Qualität der Antworten des Bots. Dabei kannst du uns mit deinem Feedback unterstützen. Am einfachsten geht dies über das integrierte Feedbacksystem. Unter jeder Antwort des Chatbots kannst du auswählen mit wie viel Sternen du die Antwort bewerten würdest. Nachdem du eine Anzahl an Sternen ausgewählt hast, öffnet sich ein Feedbackfeld. Hier kannst du dein Feedback eingeben. Natürlich kannst du uns für dein Feedback auch anrufen oder uns eine E-Mail schreiben.</p>
                    </li>
                    <p>Findest du hier keine Antwort auf deine Frage? Dann melde dich bei uns!</p>
                </ul>
            </div>
            <div className="transcriptGradientBottom"></div>
        </div>
    );
}

export default HelpPage;