import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LuInfo } from "react-icons/lu";
import { setToken, getMe, setError, clearError, removeToken } from "../../utils/utils";

export default function Login({ setAuthenticated }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [credential, setCredential] = useState('');
    const token = searchParams.get('token');

    useEffect(() => {
        if (token) {
          setToken(token);
          login(token);
        }
      }, [token]);

    async function login(loginToken) {
        setToken(loginToken);
        await getMe().then(response => {
            if (response && response.status === 200) {
                setAuthenticated(true);
                clearError();
                setSearchParams({});
            }
        }).catch(error => {
            removeToken();
            setError('Der Server ist nicht erreichbar, bitte versuchen Sie es später erneut.');
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(credential);
        }
    }
    
    return (
        <div className='Login'>
            <div className='Login__header'>
                <img src='/robot.png' width={60} height={60} alt="Robot" title='Home'/>
                <span className='Login__title'>Betriebsratsbot</span>
            </div>
            <h2 className="Login__greeting--start">Moin,</h2>
            <h2 className="Login__greeting--end">Willkommen</h2>
            <input className='Login__input' type='text' placeholder="Authentifizierungstoken..." onKeyDown={handleKeyDown} onChange={(e) => setCredential(e.target.value)} />
            <button className="Login__button" onClick={() => login(credential)}>Anmelden</button>
            <div className='tooltip info-icon'>
                <LuInfo size={35} color='black' />
                <span className="tooltiptext"> Nach Abschluss eines Abonnements senden wir Ihnen das für die Anmeldung erfordigte Token per E-Mail zu.
                    Falls Sie das Token nicht erhalten haben oder es verloren gegangen ist, kontaktieren Sie uns bitte.
                </span>
            </div>
        </div>
    )
}
