import React, { useState } from "react";
import ConversationComponent from "./conversationComponent";

export default function ConversationsComponent({
    conversations,
    conversation,
    setConversation,
    setGeneratedDocument,
    setCallToAction,
    setMessageRequest,
    setRating
}) {
    const [currentPage, setCurrentPage] = useState(1);
    const conversationsPerPage = 10;

    // Sort conversations by createdAt date, newest first
    const sortedConversations = conversations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Calculate pagination
    const indexOfLastConversation = currentPage * conversationsPerPage;
    const indexOfFirstConversation = indexOfLastConversation - conversationsPerPage;
    const currentConversations = sortedConversations.slice(indexOfFirstConversation, indexOfLastConversation);
    const totalPages = Math.ceil(sortedConversations.length / conversationsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="document-generation-page__sidebar">
            <p className="document-generation-page__conversation-title">Dokumente</p>
            <ul className="document-generation-page__conversations">
                {currentConversations.map((conv) => (
                    <ConversationComponent key={conv.id} currentConversation={conversation} conversation={conv} setConversation={setConversation} setGeneratedDocument={setGeneratedDocument} setCallToAction={setCallToAction} setMessageRequest={setMessageRequest} setRating={setRating}/>
                ))}
            </ul>
            {totalPages > 1 && <div className="pagination">
                <button className="pagination__button" onClick={handlePrevPage} disabled={currentPage === 1}>Neuere Dokumente</button>
                <span className="pagination__page">{currentPage}/{totalPages}</span>
                <button className="pagination__button" onClick={handleNextPage} disabled={currentPage === totalPages}>Ältere Dokumente</button>
            </div>}
        </div>
    );
}