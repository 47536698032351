import React from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import useWebSocket from 'react-use-websocket';
import { useState, useEffect} from 'react';
import { token, setToken, getConversationById, getRequestsMadeToday, incrementRequestMadeToday } from '../../utils/utils';

export default function ChatPrompt({role, setState, setConfidenceScore, chatV2, messagesEndRef, setAuthenticated, setRating, setComment, chatFull, setChatFull, setCallToAction, setShowCommentBox, conversation, setConversation, fetchConversations}) {
    const [query, setQuery] = useState("");
    const [websocketUrl, setWebsocketUrl] = useState(process.env.REACT_APP_WEBSOCKET_URL + "/api/v2/projects/" + process.env.REACT_APP_PROJECT_ID + "/conversations/" + conversation?.id + "/chatws" + "?bearer=" + token() + "&final_response_provider=openai");
    const timeout = 60;

    function getLatestMessageRequest(conv) {
        if (conv.messageRequests.length > 0) {
            return conv.messageRequests[conv.messageRequests.length - 1];
        }
        return null;
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom();
        if (conversation) {
            if (!chatV2 && conversation.hasUnansweredRequest()) {
                setState("processing");
            } else if (!chatV2) {
                setState("waiting");
            }
        }
      }, [conversation]);


    
    const { sendMessage, lastMessage, readyState } = useWebSocket(websocketUrl, {
        onOpen: () => setState("waiting"),
        onClose: () => setState("offline"),
        shouldReconnect: () => true,
      });

    useEffect(() => {
        setWebsocketUrl(process.env.REACT_APP_WEBSOCKET_URL + "/api/v2/projects/" + process.env.REACT_APP_PROJECT_ID + "/conversations/" + conversation?.id + "/chatws" + "?bearer=" + token() + "&final_response_provider=openai");
    }, [conversation]);

    function appendMessageRequest(prompt) {
        setConversation(conversation.addPrompt(prompt));
    }

    function appendMessageReply(reply) {
        setConversation(conversation.setLastReply(reply));
    }


    useEffect(() => {
        if (lastMessage && conversation) {
            const response = JSON.parse(lastMessage.data);
            const reply = response.reply;
            const messageRequestId = response.message_request_id;
            const messageRequests = conversation.getMessageRequests();
            const lastMessageRequest = messageRequests[messageRequests.length - 1];
            if (lastMessageRequest && lastMessageRequest.id !== messageRequestId) {
                lastMessageRequest.id = messageRequestId;
            }
            if (response.confidence_score) {
                setConfidenceScore(response.confidence_score);
            }
            if (response.state) {
                setState(response.state);
            }
            if (reply && reply !== "") {
                appendMessageReply(reply);
            }
        }
    }, [lastMessage]);

    function handleClickSendMessage(){
        if (query === "") return;
        setConfidenceScore(-1);
        resetCommentAndRating();
        appendMessageRequest(query);
        sendMessage(query);
        setQuery('');
    };

    function resetCommentAndRating() {
        setQuery('');
        setShowCommentBox(false);
        setComment('Dein Feedback ...');
        setRating(0);
        setCallToAction(true);
    }
  
    async function submit() {
        const now = new Date();
        setConfidenceScore(-1);
        if (chatFull) {
            alert('Ich kann keine weiteren Anfragen bearbeiten. Bitte erstelle einen neuen Chat.');
            return;
        }
        if (query === '') {
            alert('Bitte gib eine Frage ein.');
            return;
        }
        setConversation(conversation.addPrompt(query));
        resetCommentAndRating();
       
        const json = JSON.stringify({prompt: query, conversation_id: conversation.id})
        const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + process.env.REACT_APP_PROJECT_ID + '/chat?provider_last_query=openai';
        axios.post(url, json, {
            headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token(),
            },
            timeout: 60000 // Timeout in milliseconds
        })
        .then(async res => {
            if (res.status === 502) {
                var lastMessageSinceRequest = null;
                var updatedConversation = null;
                const timeSinceLastMessage = () => {return new Date().getTime() - now.getTime()};
                while (lastMessageSinceRequest === null && timeSinceLastMessage() < timeout * 1000) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    updatedConversation = await getConversationById(conversation.id);
                    if (updatedConversation.messageRequests.length > 0) {
                        lastMessageSinceRequest = getLatestMessageRequest(updatedConversation);
                        if (new Date(lastMessageSinceRequest.createdAt) < now) {
                            lastMessageSinceRequest = null;
                        }
                    }
                }
                if (lastMessageSinceRequest) {
                    setConversation(updatedConversation);
                    setQuery('');
                    setCallToAction(true);
                } else {
                    const newConversation =conversation.setLastReply("Leider konnte keine Antwort generiert werden. Bitte versuche es später erneut.");
                    setQuery('');
                    const allMessageRequests = newConversation.getMessageRequests();
                    const lastMessageRequest = allMessageRequests[allMessageRequests.length - 1];
                    lastMessageRequest.setId(res.data.message_request_id);
                    setConversation(newConversation);
                    fetchConversations();
                }
            } else {
                if (res.data.token_limit_reached && res.data.token_limit_reached.toString().toLowerCase() == "true") {
                    setChatFull(true);
                }
                const newConversation = conversation.setLastReply(res.data.reply);
                const allMessageRequests = newConversation.getMessageRequests();
                const lastMessageRequest = allMessageRequests[allMessageRequests.length - 1];
                lastMessageRequest.setId(res.data.message_request_id);
                setConversation(newConversation);
                fetchConversations();
            }

        })
        .catch(err => {
                    const serverMessage = "Conversation token limit exceeded";
                    if (err.message === serverMessage) {
                        setChatFull(true);
                        setConversation(conversation.setLastReply('Der Chat ist voll. Bitte erstelle einen neuen Chat.'));
                    } else if (err.code === 'ECONNABORTED') {
                        alert('Der Server hat zu lange gebraucht, um zu antworten. Bitte versuche es später erneut.');
                    } else if (err.response.status == 403) {
                        setAuthenticated(false);
                        setToken('')
                        alert('Dein Token ist abgelaufen oder nicht korrekt, bitte logge dich erneut ein')
                    } else {
                        console.log(err);
                    }
        });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendEitherChat(e);
        }
    }

    function sendEitherChat(e) {
        e.preventDefault();
        if (role !== "admin" && role !== "guest") {
            const requestsMadeToday = getRequestsMadeToday();
            if (requestsMadeToday >= 5) {
                alert('Du hast heute schon 5 Anfragen gestellt, bitte versuche es morgen erneut.');
                return;
            } else {
                incrementRequestMadeToday();
            }
        }
        if (chatV2) {
            handleClickSendMessage();
        } else {
            submit();
        }
    }

    return (
        <div className='Chat-Prompt' >
            <TextareaAutosize className='Chat-Prompt__input' value={query} onKeyDown={handleKeyDown} onChange={(e) => setQuery(e.target.value)} placeholder='Frag mich etwas zur Betriebsratsarbeit...'/>
            <button className="arrow-button" onClick={sendEitherChat}><span className="arrow"></span></button>
        </div>
    )
}