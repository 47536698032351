import { useState } from 'react';
import axios from 'axios';
import { token } from '../utils/utils';


export default function CommentBox( {messageID, setCallToAction, setShowCommentBox, project_id}) {
    const [comment, setComment] = useState('');
    function submit () {
        setCallToAction(false);
        setShowCommentBox(false);
        const json = JSON.stringify({comment: comment})
        const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + project_id + '/chat/' + messageID;
        axios.patch(url, json, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        })
        .then(() => {
            setShowCommentBox(false);
        })
        .catch(err => {
            console.log(err);
            setShowCommentBox(true);
        });
    }

    return (
        <div className='comment-box'>
            <textarea className="comment-box__input" value={comment ? comment : ''} placeholder='Dein Feedback ...' onChange={(e) => setComment(e.target.value)}></textarea>
            <button className="submit-comment" onClick={submit}>Senden</button>
        </div>
    )
}